<div class="choose-game-container">
  <h2>Welcome to Causable Admin</h2>
  <p>please choose the game that you would like to manage</p>
  <form [formGroup]="chosenGameFromGroup">
    @if(availableGames.length > 0) {
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Choose Game</mat-label>
        <mat-select formControlName="chosenGame" data-testid="select-game">
          @for(game of availableGames; track game) {
            <mat-option data-testid="game-option" [value]="game" class="choose-game-option">
              {{game.Name}}
            </mat-option>
          }
        </mat-select>
        @if(chosenGameFromControl.errors && (chosenGameFromControl.touched || chosenGameFromControl.dirty)) {
          <mat-error data-testid="chosen-game-error">
            please choose a game
          </mat-error>
        }
      </mat-form-field>
    }
  </form>

  <div class="text-end">
    <button color="primary" mat-raised-button (click)="onGameConfirmClick()" data-testid="confirm-game">
      Confirm
    </button>
  </div>
</div>
