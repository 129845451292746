import {Component, OnInit} from '@angular/core';
import {GamePageBase} from "../../shared/game-page-base";
import {GameService} from "../../services/game.service";
import {UsersService} from "../../services/users.service";
import {IAdminQueryResult} from "../../interfaces/IAdminQueryResult";
import {ColDef, FirstDataRenderedEvent, GridSizeChangedEvent} from "ag-grid-community";
import {AddNewAdminComponent} from "../../components/dialogs/add-new-admin/add-new-admin.component";
import {AdminUserTables} from "../../table-definitions/admin-user-tables";
import {AdminInviteType} from "../../enum/AdminInviteType";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {MatDialog} from "@angular/material/dialog";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {LoadingCardContentComponent} from "../../shared/loading-card-content/loading-card-content.component";
import {MatButton} from "@angular/material/button";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {MatTooltip} from "@angular/material/tooltip";
import {AgGridAngular} from "ag-grid-angular";
import {
  UserHasCausableAdminAccessDirective
} from "../../directives/permissions/user-has-causable-admin-access.directive";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-manage-users',
  standalone: true,
  templateUrl: './manage-users.component.html',
  imports: [
    LoadingCardContentComponent,
    MatButton,
    MatIcon,
    HasPermissionDirective,
    MatTooltip,
    AgGridAngular,
    UserHasCausableAdminAccessDirective
  ],
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent extends GamePageBase implements OnInit {
  public permissionTypes = PermissionTypes;
  public gameAdminUsers: IAdminQueryResult[] = [];
  public causableAdminUsers: IAdminQueryResult[] = [];
  public gameAdminColDefs: ColDef[] = this.adminUserTables.gameAdminUserTableColDefs;
  public causableAdminColDefs: ColDef[] = this.adminUserTables.causableAdminColDefs;
  public adminInviteTypes = AdminInviteType;

  constructor(gameService: GameService,
              private matDialog: MatDialog,
              private userService: UsersService,
              private adminUserTables: AdminUserTables,
              private errorHandlingService: ErrorHandlingService,
              private usersService: UsersService) {
    super(gameService)
  }

  ngOnInit() {
    this.usersService.getUpdatedAdminUsersBehaviorSubject$.subscribe({
      next: (getUpdatedUsers) => {
        if (getUpdatedUsers) {
          this.retrieveAllAdminUsers();
        }
      }
    })
  }

  gridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  retrieveAllAdminUsers() {
    this.uiState = UIStateEnum.ShowLoading;
    this.usersService.getAllGameAdminUsers(this.activeGame!).subscribe({
      next: (users) => {
        this.causableAdminUsers = users.CausableAdmins;
        this.gameAdminUsers = users.GameAdmins;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onInviteUserClick(adminInviteType: AdminInviteType) {
    const dialogRef = this.matDialog.open(AddNewAdminComponent, {
      data: adminInviteType,
      width: '650px'
    });

    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful) => {
          if (updateSuccessful) {
            this.userService.getUpdatedAdminUsersBehaviorSubject.next(true);
          }
        }
      })
  }
}
