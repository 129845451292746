import {Injectable} from "@angular/core";
import {DatePipe} from "@angular/common";
import {ColDef} from "ag-grid-community";
import {AuditEventTypesPipe} from "../pipes/audit-event-types.pipe";
import {CopyValueComponent} from "../components/cell-renderers/copy-value/copy-value.component";
import {EventSourceToDisplayPipe} from "../pipes/event-source-to-display.pipe";

@Injectable({
  providedIn: 'root'
})
export class AuditEventTables {

  private idColDef: ColDef = {
    headerName: 'Audit Id',
    field: 'Id',
    resizable: true,
    sortable: true
  };

  private createdOnColDef: ColDef = {
    headerName: 'Created On',
    field: 'CreatedOn',
    resizable: true,
    valueFormatter: params => {
      const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
      return convertedDate ? convertedDate : '';
    },
    sortable: true
  };

  private eventTypeColDef: ColDef = {
    headerName: 'Audit Event Type',
    field: 'AuditEventTypeId',
    resizable: true,
    valueFormatter: params => {
      return this.auditEventTypesPipe.transform(params.value);
    },
    sortable: true
  };

  private playerIdColDef: ColDef = {
    headerName: 'Player Id',
    field: 'PlayerId',
    cellRenderer: CopyValueComponent,
    resizable: true,
    sortable: true
  };

  private adminIdColDef: ColDef = {
    headerName: 'Admin Id',
    field: 'AdminId',
    cellRenderer: CopyValueComponent,
    resizable: true,
    sortable: true
  };

  private eventSourceIdColDef: ColDef = {
    headerName: 'Event Source',
    field: 'AuditEventSourceTypeId',
    resizable: true,
    sortable: true,
    valueFormatter: params => {
      return this.eventSourceToDisplayPipe.transform(params.value);
    },
  };

  public playerAuditEventColDefs: ColDef[] = [
    this.idColDef,
    this.createdOnColDef,
    this.playerIdColDef,
    this.eventTypeColDef,
    this.eventSourceIdColDef
  ];

  public adminAuditEventColDefs: ColDef[] = [
    this.idColDef,
    this.createdOnColDef,
    this.adminIdColDef,
    this.eventTypeColDef,
    this.eventSourceIdColDef
  ];

  public systemAuditEventColDefs: ColDef[] = [
    this.idColDef,
    this.createdOnColDef,
    this.eventTypeColDef
  ];

  constructor(private datePipe: DatePipe,
              private eventSourceToDisplayPipe: EventSourceToDisplayPipe,
              private auditEventTypesPipe: AuditEventTypesPipe) {
  }

}
