import { Injectable } from '@angular/core';
import {APIService} from "./api.service";
import {IGameTermsOfService} from "../interfaces/IGameTermsOfService";
import {IUpdateGameTosCommand} from "../interfaces/IUpdateGameTosCommand";
import {IGameLatestTermsOfServiceResult} from "../interfaces/IGameLatestTermsOfServiceResult";
import {IAddTermsOfService} from "../interfaces/IAddTermsOfService";

@Injectable({
  providedIn: 'root'
})
export class GameTOSService {

  constructor(private apiService: APIService) { }

  getTos(gameIdP: string) {
    return this.apiService.MakeGetRequest<IGameLatestTermsOfServiceResult>("game/tos/latest/byid/" + gameIdP);
  }

  updateTos(gameTos: IUpdateGameTosCommand) {
    return this.apiService.MakePutRequest<IGameTermsOfService>("game/tos", gameTos);
  }

  addTos(gameTos: IAddTermsOfService) {
    return this.apiService.MakePostRequest<IGameTermsOfService>("game/tos", gameTos);
  }
}
