<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Update Sensitive Game Settings</span>
  <ng-container dialog-content>
    <form [formGroup]="sensitiveGameSettingsForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter New License Number</mat-label>
        <input
          data-testid="update-license-number-input"
          formControlName="licenseNumber"
          type="text"

          matInput
          placeholder="enter license number">
        @if (licenseNumberControl.hasError('required')) {
          <mat-error
            data-testid="license-number-required-message">
            license number is <span class="bold-text">required</span>
          </mat-error>
        }

      </mat-form-field>
    </form>
  </ng-container>
  <ng-container actions>
    <button
      data-testid="update-sensitive-game-settings-submit"
      mat-button
      (click)="updateSensitiveSettings()"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading || !sensitiveGameSettingsForm.dirty">Update
    </button>
  </ng-container>
</app-dialog-base>

