<app-dialog-base [showLoadingSpinner]="uiState == uiStateForTemplate.ShowLoading">
  <span dialog-title>Recalculate Game Totals</span>

  <ng-container dialog-content>

    @if (recalculationCompleted) {
      <p>
        All recalculations have been submitted successfully!
      </p>

      @if (groupedEntries.length == 0) {
        <p>
          No changes are needed.
        </p>
      }
    } @else {
      <p>recalculating tool will assess all payments and game instance totals and show a summary of suggested
        changes</p>
    }

    @if (groupedEntries.length > 0) {
      <mat-tab-group>
        @for (entry of groupedEntries; track entry) {
          <mat-tab [label]="entry.EntityName">
            <ng-template matTabContent>
              <ag-grid-angular
                class="ag-theme-alpine margin-top-med large-table"
                [tooltipShowDelay]=0
                overlayNoRowsTemplate="<span>No entries found</span>"
                [rowData]="entry.Entries"
                [pagination]="true"
                [domLayout]="'autoHeight'"
                [columnDefs]="changeColDefs">
              </ag-grid-angular>
            </ng-template>
          </mat-tab>
        }
      </mat-tab-group>
    }

  </ng-container>
  <ng-container actions>
    <button mat-button
            [disabled]="uiState === uiStateForTemplate.ShowLoading"
            class="primary-button"
            (click)="onRecalculateGameClick()"
            data-testid="recalculate-game-totals-button">Recalculate
    </button>
    @if (recalculationSubmitted && groupedEntries.length > 0) {
      <button mat-button
              [disabled]="uiState === uiStateForTemplate.ShowLoading"
              class="primary-button"
              (click)="onSubmitChanges()"
              data-tesid="submit-changes-button">Approve/Commit Updates
      </button>
    }
  </ng-container>
</app-dialog-base>
