@if (uiState == uiStateForTemplate.ShowData) {
  <mat-tab-group data-testid="player-tab-group">
    <mat-tab labelClass="player-tickets-tab"
             label="Numbers"
             *hasPermission="permissionTypes.ViewPlayerNumbers">
      <ng-template matTabContent>
        <app-player-numbers-tab></app-player-numbers-tab>
      </ng-template>
    </mat-tab>

    <mat-tab label="Transactions"
             labelClass="player-transactions-tab"
             *hasPermission="permissionTypes.ViewPlayerTransactions">
      <ng-template matTabContent>
        <app-player-transactions-tab></app-player-transactions-tab>
      </ng-template>
    </mat-tab>
    <mat-tab label="Notes"
             labelClass="player-notes-tab"
             *hasPermission="permissionTypes.ModifyPlayerInfo">
      <ng-template matTabContent>
        <app-player-game-notes-tab></app-player-game-notes-tab>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
} @else if (uiState == uiStateForTemplate.ShowLoading) {
  <mat-spinner></mat-spinner>
}
