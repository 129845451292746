<h3>Admin Audit Filter</h3>
@if (uiState == uiStateForTemplate.ShowData) {

  <form [formGroup]="adminAuditFilterFormGroup" class="margin-bottom-med">
    <div class="flex-row-wrap justify-space-between">
      <mat-form-field class="flex-50" appearance="outline">
        <mat-label>Admin Id</mat-label>
        <input matInput placeholder="enter admin id" formControlName="adminId" data-testid="admin-id-input">
      </mat-form-field>
      <mat-form-field class="flex-50" appearance="outline">
        <mat-label>Event Type</mat-label>
        <mat-select placeholder="enter event type" formControlName="eventTypeValue" data-testid="event-type-select">

          @for(eventType of auditEventTypes; track eventType) {
            <mat-option [value]="eventType.Value" data-testid="event-type-option">
              {{ eventType.Name }}
            </mat-option>
          }

        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-50" appearance="outline">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input
          [rangePicker]="campaignOnePicker">
          <input matStartDate placeholder="From" formControlName="fromDate" data-testid="from-date-input">
          <input matEndDate placeholder="To" formControlName="toDate" data-testid="to-date-input">
        </mat-date-range-input>
        <mat-hint>MM-DD-YYYY – MM-DD-YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="text-end">
      <button [disabled]="!adminAuditFilterFormGroup.valid" mat-raised-button type="submit"
              class="primary-button margin-right-small" (click)="onFormSubmit()" data-testid="filter-button">Filter
      </button>
      <button mat-stroked-button class="primary-button" (click)="onResetClick()" data-testid="reset-button">Reset
      </button>
    </div>
  </form>

  <ag-grid-angular
    class="ag-theme-alpine margin-top-med"
    data-testid="admin-audit-table"
    [tooltipShowDelay]=0
    [pagination]="true"
    domLayout='autoHeight'
    [suppressPaginationPanel]="true"
    (rowClicked)="onRowSelected($event)"
    overlayNoRowsTemplate="<span>No admin audits</span>"
    (gridReady)="onGridReady($event)"
    [rowData]="adminAudits$ | async"
    [columnDefs]="adminAuditColDefs">
  </ag-grid-angular>
  <div class="custom-paginator">
    <span>{{ fromNumber }}</span> to <span>{{ toNumber }}</span> of <span
    class="margin-right-large">{{ totalRecords }}</span>
    <mat-icon (click)="onBtFirst()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">first_page</mat-icon>
    <mat-icon (click)="onBtPrevious()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">navigate_before</mat-icon>
    Page <span>{{ pageNumber }}</span> of <span>{{ totalPages }}</span>
    <mat-icon (click)="onBtNext()" [ngClass]="pageNumber === pagedAuditResponse?.TotalPages ? 'disabled' : ''">
      navigate_next
    </mat-icon>
    <mat-icon (click)="onBtLast()" [ngClass]="pageNumber === pagedAuditResponse?.TotalPages ? 'disabled' : ''">last_page
    </mat-icon>
  </div>

}
