import {Component, OnInit} from '@angular/core';
import {GamePageBase} from "../../shared/game-page-base";
import {GameService} from "../../services/game.service";
import {GameInstanceService} from "../../services/game-instance.service";
import {IGameInstanceWinner} from "../../interfaces/IGameInstanceWinner";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {DatePipe} from "@angular/common";
import {PayWinnerButtonComponent} from "../../components/cell-renderers/pay-winner-button/pay-winner-button.component";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {AgGridAngular} from "ag-grid-angular";
import {LoadingCardContentComponent} from "../../shared/loading-card-content/loading-card-content.component";

@Component({
  selector: 'app-winner-payouts',
  standalone: true,
  templateUrl: './winner-payouts.component.html',
  imports: [
    AgGridAngular,
    LoadingCardContentComponent
  ],
  styleUrls: ['./winner-payouts.component.css']
})
export class WinnerPayoutsComponent extends GamePageBase implements OnInit {

  public gameInstanceWinners: IGameInstanceWinner[] = [];

  public winnersDefs: ColDef[] = [
    {
      headerName: 'Game Number',
      width: 40,
      wrapHeaderText: true,
      field: 'GameNumber',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Draw Date',
      field: 'GameInstanceDrawDate',
      resizable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      },
      sortable: true
    },
    {
      headerName: 'First Name',
      field: 'FirstName',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Last Name',
      field: 'LastName',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Phone',
      field: 'Phone',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Email',
      resizable: true,
      sortable: true,
      field: 'Email'
    },
    {
      headerName: 'Played',
      resizable: true,
      sortable: true,
      field: 'Played'
    },
    {
      headerName: 'Amount Owed',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'AmountOwed',
      valueFormatter: params => {
        return this.currencyPipe.transform(params.value);
      }
    },
    {
      headerName: 'Actions',
      resizable: true,
      cellRenderer: PayWinnerButtonComponent
    }
  ];

  public winnersGridApi!: GridApi<IGameInstanceWinner>;

  constructor(private gameInstanceService: GameInstanceService,
              private currencyPipe: FormatCurrencyPipe,
              private errorHandlingService: ErrorHandlingService,
              private datePipe: DatePipe,
              gameService: GameService) {
    super(gameService)
  }

  ngOnInit(): void {
    this.gameInstanceService.gameInstanceWinnersByGame(this.activeGame?.Id!).subscribe({
      next: (res) => this.gameInstanceWinners = res,
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onWinnersGridReady(params: GridReadyEvent<IGameInstanceWinner>) {
    this.winnersGridApi = params.api;
    this.winnersGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }
}
