import {Injectable} from "@angular/core";
import {APIService} from "./api.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {IPlayer} from "../interfaces/player/IPlayer";
import {IPlayerSearchParams} from "../interfaces/player/IPlayerSearchParams";
import {IPlayerPerGameQueryResult} from "../interfaces/player/IPlayerPerGameQueryResult";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {IDeactivatePlayerNumbers} from "../interfaces/player/IDeactivatePlayerNumbers";
import {IReactivatePlayerNumbers} from "../interfaces/player/IReactivatePlayerNumbers";
import {IPlayerPaymentTransactions} from "../interfaces/player/IPlayerPaymentTransactions";
import {HttpParams} from "@angular/common/http";
import {IDeactivatePlayer} from "../interfaces/player/IDeactivatePlayer";
import {IReactivatePlayer} from "../interfaces/player/IReactivatePlayer";
import {IAdminUpdatePlayerBasicInfo} from "../interfaces/player/IAdminUpdatePlayerBasicInfo";
import {IPlayerNumbersForGameQueryResult} from "../interfaces/player/IPlayerNumbersForGameQueryResult";
import {createStore} from "@ngneat/elf";
import {
  getActiveEntity,
  selectActiveEntity,
  setActiveId,
  setEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import {withRequestsCache} from "@ngneat/elf-requests";
import {IPlayerQueryResult} from "../interfaces/player/IPlayerQueryResult";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PlayersService {

  private playerStore = createStore(
    {name: "player-state"},
    withEntities<IPlayerPerGameQueryResult, "Id">(),
    withActiveId()
  );

  public fetchUpdatedTicketNumbersSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public fetchUpdatedTicketNumbersSubject$: Observable<boolean> = this.fetchUpdatedTicketNumbersSubject.asObservable();

  constructor(private apiService: APIService) {
  }

  //retrieve player data tied to a particular game
  public getPlayerGameInfo(playerIdP: string, gameIdP: string): Observable<IPlayerPerGameQueryResult> {
    return this.apiService.MakeGetRequest<IPlayerPerGameQueryResult>(`player/${playerIdP}/for-game/${gameIdP}`)
      .pipe(tap(res => this.playerStore.update(setEntities([res]))));
  }

  public updateBasicPlayerInfo(commandP: IAdminUpdatePlayerBasicInfo): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/admin-update/basic-info`, commandP);
  }

  public getPlayerNumbersByGameId(playerIdP: string, gameIdP: string): Observable<IPlayerNumbersForGameQueryResult> {
    return this.apiService.MakeGetRequest<IPlayerNumbersForGameQueryResult>(`player-number/by-player/${playerIdP}/for-game/${gameIdP}`);
  }

  public getPlayerTransactionsByGameId(playerIdP: string, gameIdP: string): Observable<IPlayerPaymentTransactions[]> {
    return this.apiService.MakeGetRequest<{
      Transactions: IPlayerPaymentTransactions[]
    }>(`player/transactions/${playerIdP}/${gameIdP}`)
      .pipe(map((res) => res.Transactions));
  }

  public deactivatePlayerNumbersByGroup(deactivateCommandP: IDeactivatePlayerNumbers): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player-number/deactivate-numbers`, deactivateCommandP);
  }

  public deactivatePlayer(deactivatePlayerCommandP: IDeactivatePlayer): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/deactivate`, deactivatePlayerCommandP);
  }

  public reactivatePlayer(reactivatePlayerCommandP: IReactivatePlayer): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/reactivate`, reactivatePlayerCommandP);
  }

  public activatePlayerNumbers(reactivateCommandP: IReactivatePlayerNumbers): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player-number/reactivate-numbers`, reactivateCommandP);
  }

  public getPlayersBySearchParams(paramsP: IPlayerSearchParams) {
    let queryParams: HttpParams = new HttpParams();

    queryParams = queryParams.append('gameId', paramsP.gameId)

    if (paramsP.firstName) {
      queryParams = queryParams.append('firstName', paramsP.firstName)
    }

    if (paramsP.lastName) {
      queryParams = queryParams.append('lastName', paramsP.lastName)
    }

    if (paramsP.email) {
      queryParams = queryParams.append('email', paramsP.email)
    }

    if (paramsP.phoneNumber) {
      queryParams = queryParams.append('phoneNumber', paramsP.phoneNumber)
    }

    return this.apiService.MakeGetRequest<{ Players: IPlayer[] }>('player/search', queryParams)
      .pipe(map((res) => {
        return res.Players
      }));
  }

  public getActivePlayer() {
    return this.playerStore.query(getActiveEntity());
  }
}
