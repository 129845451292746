import {Component, OnInit} from '@angular/core';
import {SideNavService} from "../../services/side-nav.service";
import {animateText, onSideNavChange} from "../../animations/side-nav-animations";
import {GameService} from "../../services/game.service";
import {ActiveUserService} from "../../services/active-user.service";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {concatMap, map, Observable, of} from "rxjs";
import {AppConfigService} from "../../services/app-config.service";
import {MatListModule} from "@angular/material/list";
import {AsyncPipe} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {
  UserHasCausableAdminAccessDirective
} from "../../directives/permissions/user-has-causable-admin-access.directive";

@Component({
  selector: 'app-side-nav',
  standalone: true,
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  imports: [
    MatListModule,
    AsyncPipe,
    RouterLink,
    MatTooltip,
    MatIcon,
    HasPermissionDirective,
    UserHasCausableAdminAccessDirective
  ],
  animations: [onSideNavChange, animateText]
})
export class SideNavComponent implements OnInit {

  public activeGameId$ = this.gameService.selectActiveGameId();
  public userIsLoggedIn$ = this.activeUserService.userIsLoggedIn().pipe(concatMap((val) => {
    // if the user is logged in, go get all of their games and store them
    if (val) {
      return this.gameService.getGamesForUser().pipe(map(() => val));
    }
    return of(val);
  }));
  public activeUser$ = this.activeUserService.selectActiveUser();
  public availableGamesToUser$: Observable<IGameQueryResult[]> = this.gameService.selectAllAvailableGames();
  sideNavShouldOpen: boolean = true;
  public linkText: boolean = true;
  public permissionTypes = PermissionTypes;
  public currentEnvironment: string = "";

  constructor(private gameService: GameService,
              private activeUserService: ActiveUserService,
              private sideNavService: SideNavService,
              private appConfigService: AppConfigService) {
  }

  onSideNavToggle() {
    this.sideNavShouldOpen = !this.sideNavShouldOpen;

    setTimeout(() => {
      this.linkText = this.sideNavShouldOpen;
    }, 200)

    this.sideNavService.sideNavState$.next(this.sideNavShouldOpen);
  }

  ngOnInit(): void {
    this.appConfigService.appConfig$.subscribe(config => {
      this.currentEnvironment = config.currentEnvironment;
    })
  }

}
