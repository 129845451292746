<div class="one-column-page-container">
  <h2>Edit Terms Of Service</h2>

  <form [formGroup]="tosForm">
    <mat-form-field appearance="outline" class="full-width">
      <mat-hint>Game TOS</mat-hint>
      <textarea matInput formControlName="gameTos" data-testid="game-tos-input"></textarea>
      @if (gameTosControl.errors) {
        <mat-error>Game TOS is required</mat-error>
      }
    </mat-form-field>

    <div class="markdown-preview margin-top-large">
      <span>Game Tos Preview</span>
      <markdown [data]=formatMarkdown(gameTosControl.value!)></markdown>
    </div>

    @if (activeUser.CausableAdmin) {
      <mat-form-field appearance="outline" class="full-width margin-top-large">
        <mat-hint>Causable TOS</mat-hint>
        <textarea matInput formControlName="causableTos" data-testid="causable-tos-input"></textarea>
        @if (causableTosControl.errors) {
          <mat-error>Causable TOS is required</mat-error>
        }
      </mat-form-field>

      <div class="markdown-preview margin-top-large">
        <br>
        <span>Causable Tos Preview</span>
        <markdown [data]=formatMarkdown(causableTosControl.value!)></markdown>
      </div>
    }

  </form>
  <br>
  <button mat-flat-button
          color="primary"
          (click)="submit()"
          [disabled]="uiState === uiStateEnumForTemplate.ShowLoading"
          data-testid="submit-tos-form-button">Submit
  </button>

</div>
