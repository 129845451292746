@if(!rowData.Played) {
  <button mat-button
          class="primary-button"
          (click)="onPayWinnerClick()"
          matTooltip="Pay this winner"
          matTooltipPosition="right"
          matTooltipShowDelay="500"
          data-testid="pay-winner-button">
    <mat-icon>payment</mat-icon>
    Pay Winner
  </button>
}

