@if (showWrongGameMessage) {
  {{ wrongGameIdMessage }}
} @else {
  @if (newGame$ | async; as newGame) {
    <h2>Game Name: {{ newGame.newBaseGameSettings?.name }}</h2>
    <h2>Subdomain: {{ newGame.newBaseGameSettings?.subdomain }}</h2>
    <button mat-fab class="save-game" data-testid="save-draft-button" (click)="saveGame()">
      <mat-icon>save</mat-icon>
    </button>
    <mat-vertical-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>General Settings</ng-template>
        <div class="padding-top-med padding-bottom-med">
          <app-general-game-settings-form
            (newGameGeneralSettingsFormChange)="gameGeneralForm = $event"></app-general-game-settings-form>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Draw Settings</ng-template>
        <div class="padding-top-med padding-bottom-med">
          <app-game-draw-settings-form
            (newGameDrawSettingsFormChange)="gameDrawSettingsForm = $event"></app-game-draw-settings-form>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Payment Settings</ng-template>
        <div class="padding-top-med padding-bottom-med">
          <app-game-payment-settings-form
            (newGamePaymentFormChange)="gamePaymentSettingsForm = $event"></app-game-payment-settings-form>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Metadata Settings</ng-template>
        <div class="padding-top-med padding-bottom-med">
          <app-game-metadata-settings-form
            (newGameMetadataSettingsFormChange)="gameMetadataSettingsForm = $event"></app-game-metadata-settings-form>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Assets Settings</ng-template>
        <div class="padding-top-med padding-bottom-med">
          <app-game-asset-settings-form
            (newGameAssetsSettingsFormChange)="gameAssetsSettingsForm = $event"></app-game-asset-settings-form>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Add Charities</ng-template>
        @if ((charitiesForNewGame$ | async); as charityCategories) {
          @if (charityCategories.length > 0) {
            @for (charityCategory of charityCategories; track charityCategory) {
              <div class=" margin-bottom-med">
                <div class="bold-text">Category: {{ charityCategory.Category.Name }}</div>
                @for (charity of charityCategory.Charities; track charity) {
                  <div class="margin-left-small margin-top-small">- {{ charity.Name }}</div>
                }
                @if (charityCategory.Charities.length === 0) {
                  <div class="margin-top-med">You must add at least one charity to your category.</div>
                }
              </div>
            }

          } @else {
            <div class="margin-top-med">You must add at least one charity category and charity to your game.</div>
          }
        }

        <div class="padding-top-med padding-bottom-med">
          <button mat-button color="primary" data-testid="add-charities-button" (click)="addNewCharities()">Add
            Charities
          </button>
        </div>

      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Payment Tiers</ng-template>
        @if (gameGeneralForm.controls.type.value === GameTypeEnum.GoldRush) {
          <app-edit-payment-tiers-goldrush [gameSetter]="inProgressDraftGame"></app-edit-payment-tiers-goldrush>
        } @else if (gameGeneralForm.controls.type.value === GameTypeEnum.ProgressiveFiftyFifty) {
          <app-edit-payment-tiers-fifty-fifty [gameSetter]="inProgressDraftGame"></app-edit-payment-tiers-fifty-fifty>
        } @else {
          <mat-error class="margin-top-med">You must choose a game type from the general setting tabs before adding your
            payment tiers.
          </mat-error>
        }
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Game Terms of Service</ng-template>
        <app-add-tos></app-add-tos>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Validate & Complete Draft</ng-template>

        <div class="padding-top-med padding-bottom-med">
          <button mat-stroked-button color="primary" data-testid="validate-game-button" (click)="onValidateGameClick()">
            @if (uiState === uiStateEnumForTemplate.ShowRequestProcessing) {
              <mat-spinner diameter="32" color="accent"></mat-spinner>
            } @else {
              Validate and Finalize Game
            }
          </button>
        </div>

        @for (error of validationErrors; track error) {
          <div class="margin-bottom-med">- {{ error }}</div>
        }

      </mat-step>
    </mat-vertical-stepper>
  }

}
