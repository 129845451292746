<div class="one-column-page-container">
  <h2>Manage Game Settings
    <button mat-icon-button
            data-testid="open-manage-game-options-menu-button"
            id="charity-menu-button"
            *hasPermission="permissionTypes.RecalculateGameTotals"
            [matMenuTriggerFor]="menu">
      <mat-icon matTooltipPosition="right"
                matTooltip="open admin user menu"
      >more_vertical
      </mat-icon>
    </button>
  </h2>
  @if (activeGame$ | async; as activeGame) {
    <mat-tab-group data-testid="game-settings-tab-group">
      <mat-tab label="General Settings" labelClass="general-settings-tab">

        <div class="custom-card-border margin-top-med">
          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Name</div>
            <div class="flex-66" data-testid="game-settings-name">{{ activeGame.Name }}</div>
          </div>

            <div class="flex-row justify-space-between align-center custom-border-bottom" *hasPermission="permissionTypes.EditGameTos">
              <div class="flex-33 bold-text">Game Terms Of Service</div>
              <div class="flex-66">
                <button mat-stroked-button data-testid="edit-tos-button" (click)="editTos()">Edit</button>
              </div>
            </div>

            <div class="flex-row justify-space-between align-center custom-border-bottom" *hasPermission="permissionTypes.EditGameHowToPlay">
              <div class="flex-33 bold-text">How To Play Text</div>
              <div class="flex-66">
                <button mat-stroked-button data-testid="edit-how-to-play-button" (click)="editHowToPlay()">Edit</button>
              </div>
            </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Subdomain</div>
            <div class="flex-66" data-testid="game-settings-subdomain">{{ activeGame.Subdomain }}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Type</div>
            <div class="flex-66" data-testid="game-settings-type">{{ activeGame.Type | gameType }}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Time Zone</div>
            <div class="flex-66" data-testid="game-settings-timezone">{{ activeGame.Timezone }}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">License Number</div>
            <div class="flex-66">
              <span data-testid="game-settings-license-number">{{ activeGame.LicenseNumber }}</span>
              <mat-icon
                [matTooltip]="'click here to modify the license number'"
                *hasPermission="permissionTypes.ModifySensitiveGameSettings"
                data-testid="edit-license-number-button"
                class="edit-game-icon"
                (click)="onEditSensitiveSettingsClick()">edit
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Date of First Game</div>
            <div class="flex-66"
                 data-testid="game-settings-date-of-first-instance">{{ activeGame.DateOfFirstInstance | date: 'MMM d, y' }}
            </div>
          </div>
          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Date of Final Game</div>
            <div
              class="flex-66"
              data-testid="game-settings-date-of-final-instance">{{ activeGame.DateOfFinalInstance ? (activeGame.DateOfFinalInstance | date: 'MMM d, y') : '-----' }}
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Created On</div>
            <div class="flex-66" data-testid="game-settings-created-on">{{ activeGame.CreatedOn | date: 'MMM d, y' }}
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">State</div>
            <div class="flex-66">{{ activeGame.State | gameState}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">AutoPlay Enabled</div>
            <div class="flex-66">
              <mat-icon
                data-testid="game-settings-autoplay-enabled"
                [class]="activeGame.AutoplayEnabled ? 'green-text': 'red-text'">{{ activeGame.AutoplayEnabled ? 'check' : 'clear' }}
                check
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Collect Player Address</div>
            <div class="flex-66">
              <mat-icon
                data-testid="game-settings-collect-player-address"
                [class]="activeGame.CollectPlayerAddress ? 'green-text': 'red-text'">{{ activeGame.CollectPlayerAddress ? 'check' : 'clear' }}
                check
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Lock Players to Region</div>
            <div class="flex-66">
              <mat-icon
                data-testid="game-settings-lock-player-region"
                [class]="activeGame.RegionLockPlayerGeolocation ? 'green-text': 'red-text'">{{ activeGame.RegionLockPlayerGeolocation ? 'check' : 'clear' }}
                check
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Lock Players to Postal Code</div>
            <div class="flex-66">
              <mat-icon
                data-testid="game-settings-lock-postal-code"
                [class]="activeGame.RegionLockPlayerPostalCode ? 'green-text': 'red-text'">{{ activeGame.RegionLockPlayerPostalCode ? 'check' : 'clear' }}
                check
              </mat-icon>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Asset Settings" labelClass="assets-settings-tab">
        <div class="custom-card-border margin-top-med">

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Login More Info Url</div>
            <div class="flex-66" data-testid="game-settings-login-more-info">{{ activeGame.LoginMoreInfoUrl }}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Login Text</div>
            <div class="flex-66" data-testid="game-settings-login-text">{{ activeGame.LoginText }}</div>
          </div>


          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">FAQ Url</div>
            <div class="flex-66">
              <span data-testid="game-settings-faq-url">{{ activeGame.FaqUrl }}</span>
              <mat-icon
                [matTooltip]="'click here to modify the FAQ Url'"
                *hasPermission="permissionTypes.ModifySensitiveGameSettings"
                class="edit-game-icon"
                data-testid="edit-faq-url-button"
                (click)="onEditGenericSettingsClick()">edit
              </mat-icon>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Draw / Ticket Settings" labelClass="draw-ticket-settings-tab">
        <div class="custom-card-border margin-top-med">

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Day of Draw</div>
            <div class="flex-66"
                 data-testid="game-settings-day-of-draw">{{ activeGame.DrawDayOfWeek | drawDayOfWeek }}
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Time of Draw</div>
            <div class="flex-66"
                 data-testid="game-settings-time-of-draw">{{ activeGame.DrawTimeMinutesFromMidnightLocal | minutesFromMidnightToHours }}
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Notifications Start Time</div>
            <div
              class="flex-66"
              data-testid="game-settings-notifications-start-time">{{ activeGame.NotificationsStartMinutesFromMidnightLocal | minutesFromMidnightToHours }}
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Draw Frequency</div>
            <div class="flex-66"
                 data-testid="game-settings-draw-frequency">{{ activeGame.InstanceIntervalType | drawInterval }}
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Max Tickets Per Draw</div>
            <div class="flex-66" data-testid="game-settings-tickets-per-draw">{{ activeGame.MaxTicketsPerDraw }}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Max Dollar Amount Per Player</div>
            <div class="flex-66"
                 data-testid="game-settings-max-per-player">{{ activeGame.MaxAmountPlayersCanSpendPerDraw | formatCurrency }}
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game is Drawn Manually</div>
            <div class="flex-66">
              <mat-icon
                data-testid="game-settings-drawn-manually"
                [class]="activeGame.ManualDraw ? 'green-text': 'red-text'">{{ activeGame.ManualDraw ? 'check' : 'clear' }}
                check
              </mat-icon>
            </div>
          </div>
          <div class="flex-row justify-space-between custom-border-bottom">
            <div class="flex-33 bold-text">Payment Tiers</div>
            <div class="flex-66" data-testid="game-settings-payment-tiers">
              @for(tier of activeGame.PaymentTiers; track tier) {
                <div data-testid="payment-tiers">
                  {{ tier.NumberOfTickets }} for {{ tier.Price | formatCurrency }}
                </div>
              }
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  }
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item
          (click)="onRecalculateGameClick()"
          data-testid="edit-category-name-option">
    <mat-icon class="dark-grey menu-icon">edit</mat-icon>
    <span class="dark-grey menu-text">Recalculate Game Totals</span>
  </button>
</mat-menu>
