<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Activate Player Numbers</span>
  <ng-container dialog-content>
    @if (deactivatedNumbers.length > 0) {
      @if (gameType == gameTypes.ProgressiveFiftyFifty) {
        <span>Please check off all numbers you would like to reactivate</span>
        <div class="flex-row-wrap">
          @for (number of deactivatedNumbers; track number) {
            <mat-checkbox class="flex-25" [(ngModel)]="number.Checked"
                          data-testid="ticket-number-checkbox">
              {{ number.TicketNumber }}
            </mat-checkbox>
          }
        </div>
      } @else {
        <table mat-table [dataSource]="deactivatedStripsDataSource">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="Select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? toggleAllRows() : null"
                            [checked]="selectedStripsForActivation.hasValue() && isAllSelected()"
                            [indeterminate]="selectedStripsForActivation.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selectedStripsForActivation.toggle(row) : null"
                            [checked]="selectedStripsForActivation.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="GoldRushStripNumber">
            <th mat-header-cell *matHeaderCellDef> Gold Rush Strip #</th>
            <td mat-cell data-testid="gold-rush-strip-number" *matCellDef="let element"> {{ element.GoldRushStripNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="Numbers">
            <th mat-header-cell *matHeaderCellDef> Ticket Numbers</th>
            <td mat-cell *matCellDef="let element"> {{ getNumbersString(element.Numbers) }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              (click)="selectedStripsForActivation.toggle(row)">
          </tr>
        </table>
      }
    } @else {
      <p class="red-text">looks like the user does not have active numbers to deactivate</p>
    }

  </ng-container>
  <ng-container actions>
    <button
      data-testid="confirm-change-category-button"
      mat-button
      (click)="activateNumbers()"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading || deactivatedNumbers.length === 0">
      Activate player numbers
    </button>
  </ng-container>
</app-dialog-base>
