<div class="flex-row justify-start align-start margin-top-med flex-row-wrap">
  <small class="full-width margin-bottom-small">*Reporting dates are displayed in local time</small>
  <mat-form-field appearance="outline">
    <mat-label>Draw Date</mat-label>
    <input placeholder="filter by draw date" matInput [matDatepicker]="picker" [(ngModel)]="drawDate"
           (ngModelChange)="onDateInputChange($event)" data-testid="per-draw-report-datepicker-input">
    <mat-datepicker-toggle matSuffix [for]="picker" data-testid="per-draw-report-datepicker-toggle"></mat-datepicker-toggle>
    <mat-hint>YYYY-MM-DD</mat-hint>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<button class="margin-top-small" mat-button color="primary" (click)="resetFilter()" matTooltip="clear date filter"
        data-testid="per-draw-report-reset-filter">Reset <mat-icon>refresh</mat-icon></button>

<h3>{{chosenGame?.Name}} Draws</h3>
<div class="full-width">
  <ag-grid-angular
    id="reportsGrid"
    class="ag-theme-alpine large-table"
    [rowData]="filteredGameInstances"
    rowSelection="single"
    (rowClicked)="onRowSelected($event)"
    (gridReady)="onGameInstanceGridReady($event)"
    [pagination]="true"
    rowClass="table-row table-row-hover"
    [paginationPageSize]="10"
    [columnDefs]="columnDefs">
  </ag-grid-angular>
</div>
