<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Charity Info</span>

  <ng-container dialog-content>
    @if (uiState === uiStateEnumForTemplate.ShowData) {
      <mat-tab-group>
        <mat-tab label="Payments">
          <ag-grid-angular
            class="ag-theme-alpine margin-top-med large-table"
            [tooltipShowDelay]=0
            overlayNoRowsTemplate="<span>No tickets for this game</span>"
            (gridReady)="onPaymentsGridReady($event)"
            [rowData]="charityPayments"
            [columnDefs]="paymentsColDefs">
          </ag-grid-angular>
        </mat-tab>
        <mat-tab label="Contacts">
          <ag-grid-angular
            class="ag-theme-alpine margin-top-med large-table"
            [tooltipShowDelay]=0
            overlayNoRowsTemplate="<span>No tickets for this game</span>"
            (gridReady)="onContactsGridReady($event)"
            [rowData]="charityContacts"
            [columnDefs]="contactsColDefs">
          </ag-grid-angular>
        </mat-tab>
      </mat-tab-group>
    }

  </ng-container>
  <ng-container actions></ng-container>
</app-dialog-base>
