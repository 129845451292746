import {Injectable} from '@angular/core';
import {concatMap, Observable} from 'rxjs';
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {APIService} from "./api.service";
import {getAllEntities, selectAllEntities, setEntities, withEntities} from "@ngneat/elf-entities";
import {IGameQueryResult} from "../interfaces/IGameQueryResult";
import {withRequestsCache} from "@ngneat/elf-requests";
import {tap} from "rxjs/operators";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {IUpdateSensitiveGameSettings} from "../interfaces/IUpdateSensitiveGameSettings";
import {IUpdateGameSettings} from "../interfaces/IUpdateGameSettings";
import {IRecalculateGameEntriesResult} from "../interfaces/IRecalculateGameEntriesResult";
import {IRecalculateGameEntriesCommand} from "../interfaces/IRecalculateGameEntriesCommand";
import {IGroupedRecalculationEntries} from "../interfaces/IGroupedRecalculationEntries";
import {IPaymentTier} from "../interfaces/player/IPaymentTier";
import {GameStateEnum} from "../enum/GameStateEnum";
import {IAddPaymentTiersToGame} from "../interfaces/IAddPaymentTiersToGame";
import {IAddNewPaymentTier} from "../interfaces/IAddNewPaymentTier";

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private gameStore = createStore(
    {name: 'game'},
    withProps<{ activeGame: IGameQueryResult | null }>({
      activeGame: null
    }),
    withRequestsCache<'game-cache'>()
  );

  private potentialGamesStore = createStore(
    {name: 'all-games-state'},
    withEntities<IGameQueryResult, 'Id'>({idKey: 'Id'}),
    withRequestsCache<'all-games'>()
  );

  constructor(private apiService: APIService) {
  }

  public getActiveGamePaymentTiers(): IPaymentTier[] {
    return this.gameStore.query((store) => store.activeGame ? store.activeGame?.PaymentTiers : []);
  }

  public getAllPaymentTiers(gameIdP: string): Observable<IPaymentTier[]> {
    return this.apiService.MakeGetRequest<IPaymentTier[]>(`game/payment-tiers/${gameIdP}`);
  }

  public addPaymentTierToGame(newTierRequest: IAddPaymentTiersToGame): Observable<IGameQueryResult> {
    return this.apiService.MakePostRequest(`game/payment-tiers-to-game`, newTierRequest).pipe(concatMap(() => {
      return this.getGameByGameId(newTierRequest.gameId);
    }));
  }

  public addNewPaymentTier(newTierRequest: IAddNewPaymentTier): Observable<any> {
    return this.apiService.MakePostRequest(`game/payment-tier`, newTierRequest);
  }

  public getGamesForUser(): Observable<IGameQueryResult[]> {
    return this.apiService.MakeGetRequest<IGameQueryResult[]>(`game/all-for-user`).pipe(tap((gamesByGroup) => {
      this.potentialGamesStore.update(setEntities(gamesByGroup));
    }));
  }

  public updateSensitiveGameSettings(updatedSettingsP: IUpdateSensitiveGameSettings): Observable<IGameQueryResult> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`game/sensitive-settings`, updatedSettingsP).pipe(concatMap(() => {
      return this.getGameByGameId(updatedSettingsP.gameId).pipe(tap((gameP) => {
        this.updateActiveGame(gameP);
      }));
    }));
  }

  public updateGenericGameSettings(updatedSettingsP: IUpdateGameSettings): Observable<IGameQueryResult> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`game/generic-settings`, updatedSettingsP).pipe(concatMap(() => {
      return this.getGameByGameId(updatedSettingsP.gameId).pipe(tap((gameP) => {
        this.updateActiveGame(gameP);
      }));
    }));
  }

  public getGameByGameId(gameIdP: string): Observable<IGameQueryResult> {
    return this.apiService.MakeGetRequest<IGameQueryResult>(`game/${gameIdP}`);
  }

  public activeGame(): IGameQueryResult {
    return this.gameStore.query(state => state.activeGame!);
  }

  public clearActiveGame() {
    this.gameStore.reset();
  }

  public selectActiveGameId(): Observable<string> {
    return this.gameStore.pipe(select((state) => state.activeGame?.Id!));
  }

  public selectActiveGame$(): Observable<IGameQueryResult | undefined> {
    return this.gameStore.pipe(select((state) => state.activeGame!));
  }

  public updateActiveGame(gameP: IGameQueryResult) {
    this.gameStore.update(setProp("activeGame", gameP));
  }

  public getAllAvailableGames(): IGameQueryResult[] {
    return this.potentialGamesStore.query(getAllEntities());
  }

  public getAllDraftGames(): IGameQueryResult[] {
    return this.potentialGamesStore.query(getAllEntities()).filter((game) => game.State == GameStateEnum.Draft);
  }

  public selectAllAvailableGames(): Observable<IGameQueryResult[]> {
    return this.potentialGamesStore.pipe(selectAllEntities());
  }

  public recalculateGame(): Observable<IGroupedRecalculationEntries[]> {
    return this.apiService.MakeGetRequest<IGroupedRecalculationEntries[]>(`game/recalculate/${this.activeGame().Id}`);
  }

  public commitRecalculationChanges(): Observable<IRecalculateGameEntriesResult> {
    let command: IRecalculateGameEntriesCommand = {
      gameId: this.activeGame().Id
    };

    return this.apiService.MakePutRequest<IRecalculateGameEntriesResult>(`game/commit-recalculation-updates`, command);
  }

  public generateAssetUrl(desiredAssetP: string, cdnRoot: string) {
    return `${cdnRoot}/assets/${this.activeGame().Subdomain}/${desiredAssetP}`;
  }
}
