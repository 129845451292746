import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NewGameService} from "../../../services/new-game.service";
import {IGameGeneralSettingsForm} from "../../../interfaces/new-game/forms/IGameGeneralSettingsForm";
import {MatError, MatFormField, MatFormFieldModule, MatHint, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatCheckbox} from "@angular/material/checkbox";
import {NgIf} from "@angular/common";
import {MatInput} from "@angular/material/input";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";

@Component({
  selector: 'app-general-game-settings-form',
  standalone: true,
  templateUrl: './general-game-settings-form.component.html',
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatHint,
    MatError,
    MatLabel,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCheckbox,
    NgIf,
    MatInput
  ],
  styleUrl: './general-game-settings-form.component.css'
})
export class GeneralGameSettingsForm implements OnInit {

  private newGameService: NewGameService = inject(NewGameService);
  protected readonly GameTypeEnum = GameTypeEnum;

  newGameGeneralSettingsForm: FormGroup<IGameGeneralSettingsForm> = new FormGroup<IGameGeneralSettingsForm>({
    name: new FormControl<string>('', Validators.required),
    subdomain: new FormControl<string>('', Validators.required),
    allowFreeTickets: new FormControl<boolean>(false, Validators.required),
    autoplayEnabled: new FormControl<boolean>(false, Validators.required),
    collectPlayerAddress: new FormControl<boolean>(false, Validators.required),
    dateOfFinalInstance: new FormControl<Date | null>(null),
    dateOfFirstInstance: new FormControl<Date>(new Date(), Validators.required),
    displayNonWinnerName: new FormControl<boolean>(false, Validators.required),
    licenseNumber: new FormControl<string>(''),
    loginText: new FormControl<string>('', Validators.required),
    maxReceiptAttempts: new FormControl<number>(0, Validators.required),
    pushNotificationMessageFeeCost: new FormControl<number>(0, Validators.required),
    regionLockPlayerGeolocation: new FormControl<boolean>(false, Validators.required),
    regionLockPlayerPostalCode: new FormControl<boolean>(false, Validators.required),
    senderEmail: new FormControl<string>('', [Validators.required, Validators.email]),
    timezone: new FormControl<string>('', Validators.required),
    type: new FormControl<number>(1, Validators.required),
    contactEmail: new FormControl<string>('', [Validators.required, Validators.email]),
    loginMoreInfoUrl: new FormControl<string>(''),
    randomApiOverrideId: new FormControl<string>(''),
    twilioAuthToken: new FormControl<string>('',),
    twilioPhoneNumber: new FormControl<string>(''),
    twilioSid: new FormControl<string>(''),
    maxPledgesPerPlayer: new FormControl<number>(4, [Validators.required, Validators.min(1), Validators.max(5)]),
    shortName: new FormControl<string>('')
  });

  @Output() newGameGeneralSettingsFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.populateGameGeneralFromState()
  }

  onFormChange() {
    this.newGameGeneralSettingsFormChange.emit(this.newGameGeneralSettingsForm)
  }

  private populateGameGeneralFromState() {
    const newGameGeneralInfo = this.newGameService.getNewGameRequest()?.newGameGeneral;
    if (newGameGeneralInfo) {
      this.newGameGeneralSettingsForm.controls.timezone.setValue(newGameGeneralInfo.timezone!);
      this.newGameGeneralSettingsForm.controls.allowFreeTickets.setValue(newGameGeneralInfo.allowFreeTickets!);
      this.newGameGeneralSettingsForm.controls.autoplayEnabled.setValue(newGameGeneralInfo.autoplayEnabled!);
      this.newGameGeneralSettingsForm.controls.collectPlayerAddress.setValue(newGameGeneralInfo.collectPlayerAddress!);
      this.newGameGeneralSettingsForm.controls.type.setValue(newGameGeneralInfo.type ? newGameGeneralInfo.type : GameTypeEnum.GoldRush);
      this.newGameGeneralSettingsForm.controls.dateOfFinalInstance.setValue(newGameGeneralInfo.dateOfFinalInstance!);
      this.newGameGeneralSettingsForm.controls.dateOfFirstInstance.setValue(newGameGeneralInfo.dateOfFirstInstance!);
      this.newGameGeneralSettingsForm.controls.pushNotificationMessageFeeCost.setValue(newGameGeneralInfo.pushNotificationMessageFeeCost!);
      this.newGameGeneralSettingsForm.controls.senderEmail.setValue(newGameGeneralInfo.senderEmail!);
      this.newGameGeneralSettingsForm.controls.maxReceiptAttempts.setValue(newGameGeneralInfo.maxReceiptAttempts!);
      this.newGameGeneralSettingsForm.controls.displayNonWinnerName.setValue(newGameGeneralInfo.displayNonWinnerName!);
      this.newGameGeneralSettingsForm.controls.loginText.setValue(newGameGeneralInfo.loginText!);
      this.newGameGeneralSettingsForm.controls.licenseNumber.setValue(newGameGeneralInfo.licenseNumber!);
      this.newGameGeneralSettingsForm.controls.regionLockPlayerGeolocation.setValue(newGameGeneralInfo.regionLockPlayerGeolocation!);
      this.newGameGeneralSettingsForm.controls.regionLockPlayerPostalCode.setValue(newGameGeneralInfo.regionLockPlayerPostalCode!);
      this.newGameGeneralSettingsForm.controls.contactEmail.setValue(newGameGeneralInfo.contactEmail!);
      this.newGameGeneralSettingsForm.controls.shortName.setValue(newGameGeneralInfo.shortName!);
    }

    this.newGameGeneralSettingsFormChange.emit(this.newGameGeneralSettingsForm)
  }

}
