<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
@if (selectedGameInstance) {
  <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med custom-card-border">

    <div class="detail-section">
      <div class="detail-label">Started On</div>
      <div class="detail-value" data-testid="game-started-on">{{ selectedGameInstance.StartedOn | date: 'MMM d, y' }}
      </div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Draw Date & Time</div>
      <div class="detail-value"
           data-testid="game-ended-on">{{ selectedGameInstance.EndedOn | date: 'MMM d, y hh:mm a' }}
      </div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Status</div>
      <div class="detail-value"
           data-testid="game-instance-status">{{ selectedGameInstance.State | gameInstanceStatus }}
      </div>
    </div>

  </div>
}

<div class="flex-row justify-center align-center">
  @if (!noTicketChartData) {
    <div class="flex-50">
      <canvas data-testid="tickets-chart" id="ticketsChart" class="table-size"></canvas>
    </div>
  }

  @if (!noManualAutoPlayChartData) {
    <div class="flex-50">
      <canvas data-testid="manual-auto-sales-chart" id="manualAutoPlayChart" class="table-size"></canvas>
    </div>
  }

</div>

<div class="flex-row justify-space-between align-end">
  <div class="flex-50">
    <canvas data-testid="player-ticket-chart" id="playersTicketsLineChart"></canvas>
  </div>
  @if (!noSalesPerDayLineChartData) {
    <div class="flex-50">
      <canvas data-testid="sales-per-day-chart" id="salesPerDayLineChart"></canvas>
    </div>
  }

</div>

@if (!noCharitySalesBarGraphData) {
  <div class="flex-row justify-start align-center">
    <canvas data-testid="charity-sales-chart" id="charitySalesBarGraph" class="table-size"></canvas>
  </div>

}
