import {Component, Inject, OnInit} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {IGameInstanceWinner} from "../../../interfaces/IGameInstanceWinner";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInput} from "@angular/material/input";
import {PipesModule} from "../../../pipes/pipes.module";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-play-winner-dialog',
  standalone: true,
  templateUrl: './play-winner-dialog.component.html',
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInput,
    ReactiveFormsModule,
    PipesModule,
    MatProgressSpinner,
    MatDialogActions,
    MatDialogClose,
    MatButton
  ],
  styleUrls: ['./play-winner-dialog.component.css']
})
export class PlayWinnerDialogComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  public makeWinnerPaymentForm = new FormGroup({
    paymentDateControl: new FormControl<Date>(new Date(), Validators.required),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public winner: IGameInstanceWinner) {
  }

  ngOnInit(): void {
  }

}
