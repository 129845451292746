<h2 mat-dialog-title>Pay {{ winner.FirstName }} {{ winner.LastName }}</h2>
<mat-dialog-content class="mat-typography">

  <div class="margin-bottom-med">
    <div class="bold-text">Game Number: {{ winner.GameNumber }}</div>
  </div>

  <form [formGroup]="makeWinnerPaymentForm">
    <mat-form-field appearance="outline">
      <mat-label>Choose a date</mat-label>
      <input matInput formControlName="paymentDateControl" [matDatepicker]="picker">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

  </form>

  <div class="margin-top-med text-center">
    <div class="bold-text">Payment amount</div>
    <div class="bold-text">{{ winner.AmountOwed | formatCurrency }}</div>
  </div>
  @if (uiState === uiStateEnumForTemplate.ShowLoading) {
    <mat-spinner></mat-spinner>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" data-testid="cancel-button">Cancel</button>
  <button mat-button class="primary-button" [disabled]="!makeWinnerPaymentForm.valid"
          data-testid="confirm-payment-button">Confirm Payment
  </button>
</mat-dialog-actions>
