import {NgModule} from '@angular/core';
import {DatePipe, JsonPipe} from "@angular/common";
import {GameInstanceStatusPipe} from "./game-instance-status.pipe";
import {GameTypePipe} from "./game-type.pipe";
import {SubscriptionStatusPipe} from "./subscription-status.pipe";
import {FormatCurrencyPipe} from "./format-currency.pipe";
import {DrawDayOfWeekPipe} from "./draw-day-of-week.pipe";
import {MinutesFromMidnightPipe} from "./minutes-from-midnight.pipe";
import {DrawIntervalPipe} from "./draw-interval.pipe";
import {DisplayInvalidEmailReasonPipe} from "./display-invalid-email-reason.pipe";
import {EventSourceToDisplayPipe} from "./event-source-to-display.pipe";
import {PrettyPrintPipe} from "./pretty-print.pipe";

import {AuditEventTypesPipe} from "./audit-event-types.pipe";
import {PhoneFormatPipe} from "./phone-format.pipe";
import {GameStatePipe} from "./game-state.pipe";
@NgModule({
  declarations: [
    GameInstanceStatusPipe,
    GameTypePipe,
    PhoneFormatPipe,
    SubscriptionStatusPipe,
    GameStatePipe,
    AuditEventTypesPipe,
    FormatCurrencyPipe,
    DrawDayOfWeekPipe,
    EventSourceToDisplayPipe,
    PrettyPrintPipe,
    DrawIntervalPipe,
    DisplayInvalidEmailReasonPipe,
    MinutesFromMidnightPipe
  ],
  exports: [
    GameInstanceStatusPipe,
    DrawIntervalPipe,
    PrettyPrintPipe,
    GameTypePipe,
    DrawDayOfWeekPipe,
    AuditEventTypesPipe,
    PhoneFormatPipe,
    SubscriptionStatusPipe,
    FormatCurrencyPipe,
    MinutesFromMidnightPipe,
    GameStatePipe,
    DisplayInvalidEmailReasonPipe,
    EventSourceToDisplayPipe
  ],
  providers: [
    DatePipe,
    JsonPipe,
    MinutesFromMidnightPipe,
    GameInstanceStatusPipe,
    DrawIntervalPipe,
    AuditEventTypesPipe,
    DrawDayOfWeekPipe,
    GameTypePipe,
    PhoneFormatPipe,
    GameStatePipe,
    EventSourceToDisplayPipe,
    PrettyPrintPipe,
    SubscriptionStatusPipe,
    FormatCurrencyPipe,
    DisplayInvalidEmailReasonPipe
  ]
})
export class PipesModule
{
}
