<div class="one-column-page-container">
  <h2>Game Instance</h2>

  <mat-form-field appearance="outline" class="full-width" data-testid="game-lookup-form">
    <mat-label>Game Instance Lookup</mat-label>
    <input placeholder="enter game instance id" matInput [(ngModel)]="gameInstanceId" data-testid="game-instance-id-input">
    <button mat-icon-button matSuffix (click)="findGameInstance()" data-testid="find-game-instance-button">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  @if(uiStateEnum === uiStateEnumForTemplate.ShowLoading) {
    <mat-spinner></mat-spinner>
  }@else if(uiStateEnum === uiStateEnumForTemplate.ShowData && comprehensiveGameInstanceData) {
    <mat-card>
      <mat-card-content data-testid="game-info-card">
        <mat-tab-group>
          <mat-tab label="Game Instance">
            <ng-template matTabContent>
              <mat-card>
                <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-large">
                  <div class="detail-section">
                    <div class="detail-label">State</div>
                    <div class="detail-value" data-testid="game-state">{{ comprehensiveGameInstanceData.GameInstance.State | gameInstanceStatus }}
                    </div>
                  </div>
                  <div class="detail-section">
                    <div class="detail-label">Created On</div>
                    <div
                      class="detail-value" data-testid="game-created-on">{{ comprehensiveGameInstanceData.GameInstance.CreatedOn | date: 'MMM d, y HH:mm' }}
                    </div>
                  </div>
                  <div class="detail-section">
                    <div class="detail-label">Started On</div>
                    <div
                      class="detail-value" data-testid="game-started-on">{{ comprehensiveGameInstanceData.GameInstance.StartedOn | date: 'MMM d, y HH:mm' }}
                    </div>
                  </div>
                  <div class="detail-section">
                    <div class="detail-label">Ended On</div>
                    <div
                      class="detail-value" data-testid="game-ended-on">{{ comprehensiveGameInstanceData.GameInstance.EndedOn | date: 'MMM d, y HH:mm' }}
                    </div>
                  </div>
                  <div class="detail-section">
                    <div class="detail-label">Starting Pot</div>
                    <div
                      class="detail-value">{{ comprehensiveGameInstanceData.GameInstance.StartingPot | formatCurrency }}
                    </div>
                  </div>
                  <div class="detail-section">
                    <div class="detail-label">Causable Fee Multiplier</div>
                    <div class="detail-value" data-testid="game-causable-fee-multiplier">{{ comprehensiveGameInstanceData.GameInstance.CausableFeeMultiplier }}</div>
                  </div>
                  <div class="detail-section">
                    <div class="detail-label">Starting Charity Contribution</div>
                    <div
                      class="detail-value" data-testid="game-starting-charity-contribution">{{ comprehensiveGameInstanceData.GameInstance.StartingCharityContribution | formatCurrency }}
                    </div>
                  </div>
                  <div class="detail-section">
                    <div class="detail-label">Starting Payment Fees</div>
                    <div
                      class="detail-value" data-testid="game-starting-payment-fees">{{ comprehensiveGameInstanceData.GameInstance.StartingPaymentFees | formatCurrency }}
                    </div>
                  </div>
                  <div class="detail-section">
                    <div class="detail-label">Starting Payment Fees Tax</div>
                    <div
                      class="detail-value">{{ comprehensiveGameInstanceData.GameInstance.StartingPaymentFeesTax | formatCurrency }}
                    </div>
                  </div>
                </div>
              </mat-card>

            </ng-template>
          </mat-tab>
          <mat-tab label="Game Instance Player Numbers">
            <ng-template matTabContent>
              <h2 class="margin-top-large">Game Instance Player Numbers</h2>
              <form [formGroup]="playerNumberFilterForm" class="flex-row-wrap justify-space-between align-center">
                <mat-form-field class="flex-33" appearance="outline">
                  <mat-label>Player Number Id</mat-label>
                  <input matInput placeholder="enter player number id" formControlName="playerNumberId" data-testid="number-id-input">
                </mat-form-field>
                <mat-form-field class="flex-33" appearance="outline">
                  <mat-label>Player Payment Id</mat-label>
                  <input matInput placeholder="enter payment id" formControlName="playerPaymentId">
                </mat-form-field>
                <mat-form-field class="flex-33" appearance="outline">
                  <mat-label>Player Id</mat-label>
                  <input matInput placeholder="enter player id" formControlName="playerId">
                </mat-form-field>
                <div class="text-end margin-bottom-med">
                  <button matTooltip="search players"
                          data-testid="search-players-button"
                          mat-raised-button
                          type="submit"
                          color="primary"
                          class="margin-right-small"
                          (click)="onFormSubmit()">Filter GIPN's
                    <mat-icon>search</mat-icon>
                  </button>
                  <button matTooltip="reset filters"
                          data-testid="reset-players-button"
                          mat-raised-button
                          color="warn"
                          (click)="onResetClick()">Reset Parameters
                    <mat-icon>refresh</mat-icon>
                  </button>
                </div>
              </form>
              <ag-grid-angular
                class="ag-theme-alpine margin-top-med large-table"
                [tooltipShowDelay]=0
                overlayNoRowsTemplate="<span>No entries found</span>"
                [rowData]="filteredGameInstancePlayerNumbers"
                [pagination]="true"
                [paginationPageSize]="25"
                [domLayout]="'autoHeight'"
                [columnDefs]="gameInstanceNumbersColDefs">
              </ag-grid-angular>
            </ng-template>
          </mat-tab>
        </mat-tab-group>

      </mat-card-content>
    </mat-card>
  }

  @if(showNoGameInstanceFound) {
    <mat-card data-testid="no-game-instances-found-error" class="red-background white-text">
      <mat-card-content>Looks there are no game instances that match that ID</mat-card-content>
    </mat-card>
  }

</div>
