<h2>Winner Payouts</h2>
<h3 data-testid="active-game-name">{{ activeGame.Name }} - Winners</h3>
@if (uiState === uiStateForTemplate.ShowData) {
  <ag-grid-angular
    class="ag-theme-alpine margin-top-med margin-bottom-med"
    [tooltipShowDelay]=0
    domLayout='autoHeight'
    overlayNoRowsTemplate="<span>No charities for this game</span>"
    (gridReady)="onWinnersGridReady($event)"
    [rowData]="gameInstanceWinners"
    [columnDefs]="winnersDefs">
  </ag-grid-angular>
} @else {
  <app-loading-card-content></app-loading-card-content>
}

