import {Component, OnInit} from '@angular/core';
import {map, Observable} from "rxjs";
import {ColDef, RowClickedEvent} from "ag-grid-community";
import {AuditingService} from "../../../services/auditing.service";
import {AuditEventTables} from "../../../table-definitions/audit-event-tables";
import {IAuditEvent} from "../../../interfaces/audit/IAuditEvent";
import {AuditTablesBase} from "../audit-tables-base";
import {DateService} from "../../../services/date.service";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {AuditingDataDisplayComponent} from "../../dialogs/auditing-data-display/auditing-data-display.component";
import {EventSourceType} from "../../../enum/EventSourceType";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";
import {GameService} from "../../../services/game.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatFormField, MatFormFieldModule, MatHint, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButton} from "@angular/material/button";
import {AgGridAngular} from "ag-grid-angular";
import {AsyncPipe, NgClass} from "@angular/common";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-system-audit-table',
  templateUrl: './system-audit-table.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatDatepickerModule,
    MatFormFieldModule,
    MatButton,
    MatHint,
    MatLabel,
    AgGridAngular,
    AsyncPipe,
    MatIcon,
    NgClass
  ],
  styleUrls: ['./system-audit-table.component.css']
})
export class SystemAuditTableComponent extends AuditTablesBase implements OnInit {

  public systemAudits$: Observable<IAuditEvent[]> = this.auditingService.systemAudits$.pipe(map((responseP) => {
    this.pagedAuditResponse = responseP;
    return responseP.Data;
  }));
  public systemAuditColDefs: ColDef[] = this.auditEventTables.systemAuditEventColDefs;

  public systemAuditFilterFormGroup = new FormGroup(
    {
      eventTypeValue: this.eventTypeFormControl,
      fromDate: this.fromDateFormControl,
      toDate: this.toDateFormControl
    }
  );

  constructor(auditingService: AuditingService,
              dateService: DateService,
              matDialog: MatDialog,
              errorHandlingService: ErrorHandlingService,
              gameService: GameService,
              auditEventTables: AuditEventTables) {
    super(auditingService, dateService, matDialog, gameService, errorHandlingService, auditEventTables);
  }

  ngOnInit() {
    this.auditingService.retrieveSystemAudits(this.defaultFilter).subscribe({
        next: () => {
          this.uiState = UIStateEnum.ShowData
        },
        error: (err) => {
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      }
    )
  }

  onRowSelected(auditP: RowClickedEvent<IAuditEvent>) {
    this.matDialog.open(AuditingDataDisplayComponent, {
      data: {
        audit: auditP.data,
        eventSource: EventSourceType.System
      }
    })
  }

  override onResetClick() {
    super.onResetClick();
    this.getPaginatedResponse(1);
  }

  getPaginatedResponse(pageNumberP: number): void {

    this.auditingService.retrieveSystemAudits({
      fromDate: new Date(this.fromDateFormControl.value),
      toDate: new Date(this.toDateFormControl.value),
      pageNumber: pageNumberP,
      pageSize: 15,
      eventTypeValue: this.eventTypeFormControl.value,
      gameId: this.gameService.activeGame().Id
    }).subscribe({
      next: () => {
        this.updatePaginator();
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }
}
