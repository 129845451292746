import {Component, OnInit} from '@angular/core';
import {MatError, MatFormField, MatHint, MatInput} from "@angular/material/input";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MarkdownComponent} from "ngx-markdown";
import {MatButton} from "@angular/material/button";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {GameService} from "../../../services/game.service";
import {GameTOSService} from "../../../services/game-tos.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {IAddTermsOfService} from "../../../interfaces/IAddTermsOfService";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {NewGameService} from "../../../services/new-game.service";

@Component({
  selector: 'app-add-tos',
  standalone: true,
  imports: [
    MatInput,
    ReactiveFormsModule,
    MarkdownComponent,
    MatButton,
    MatError,
    MatFormField,
    MatHint
  ],
  templateUrl: './add-tos.component.html',
  styleUrl: './add-tos.component.css'
})
export class AddTosComponent implements OnInit {
  public uiState = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;

  public gameTosControl: FormControl<string | null> = new FormControl<string | null>("", Validators.required);
  public causableTosControl: FormControl<string | null> = new FormControl<string | null>("", Validators.required);
  public newGameId?: string = this.newGameService.newGameId();
  public activeUser = this.activeUserService.activeUser();
  public showAlreadyExistsMessage = false;
  public tosForm = new FormGroup({
    gameTos: this.gameTosControl,
    causableTos: this.causableTosControl
  });

  constructor(private newGameService: NewGameService,
              private gameTOSService: GameTOSService,
              private errorHandlingService: ErrorHandlingService,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService) {
  }

  ngOnInit(): void {
    this.checkForTos();
  }

  private checkForTos() {
    this.gameTOSService.getTos(this.newGameId!).subscribe({
      next: (result) => {
        if (result !== null) {
          this.showAlreadyExistsMessage = true;
          this.uiState = UIStateEnum.ShowData;
        }
      }, error: () => {
        // will return a 404 if there is no tos for the game
        this.showAlreadyExistsMessage = false;
        this.uiState = UIStateEnum.ShowData;

      }
    })
  }

  formatMarkdown(textP: string) {
    if (textP == undefined) {
      return "";
    }
    return textP.replace(/\n/, "<br>");
  }

  submit() {
    if (!this.tosForm.valid) {
      this.tosForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let tos: IAddTermsOfService = {
      GameTos: this.gameTosControl.value!,
      CausableTos: this.causableTosControl.value!,
      GameId: this.newGameId!
    };

    this.gameTOSService.addTos(tos).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar("Successfully created terms of service!");
        this.checkForTos();
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    });
  }
}
