import {Component} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {GameService} from "../../services/game.service";
import {GameTOSService} from "../../services/game-tos.service";
import {IGameTermsOfService} from "../../interfaces/IGameTermsOfService";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {IUpdateGameTosCommand} from "../../interfaces/IUpdateGameTosCommand";
import {ActiveUserService} from "../../services/active-user.service";
import {Router} from "@angular/router";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MarkdownComponent} from "ngx-markdown";
import {MatButton} from "@angular/material/button";
import {SnackbarService} from "../../services/snackbar.service";

@Component({
  selector: 'app-toseditor',
  standalone: true,
  templateUrl: './toseditor.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MarkdownComponent,
    MatButton
  ],
  styleUrl: './toseditor.component.scss'
})
export class TOSEditorComponent {
  public uiState = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public activeGame = this.gameService.activeGame();
  public activeUser = this.activeUserService.activeUser();
  public tosId: string = "";

  public gameTosControl: FormControl<string | null> = new FormControl<string | null>("", Validators.required);
  public causableTosControl: FormControl<string | null> = new FormControl<string | null>("", Validators.required);

  public tosForm = new FormGroup({
    gameTos: this.gameTosControl,
    causableTos: this.causableTosControl
  });

  constructor(private gameService: GameService,
              private gameTOSService: GameTOSService,
              private activeUserService: ActiveUserService,
              private errorHandlingService: ErrorHandlingService,
              private snackBarService: SnackbarService,
              private router: Router) {
    this.populateForm();
  }

  submit() {
    if (!this.tosForm.valid) {
      this.tosForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let tos: IGameTermsOfService = {
      Id: this.tosId,
      GameTos: this.gameTosControl.value!,
      CausableTos: this.causableTosControl.value!,
      GameId: this.activeGame.Id
    };

    let command: IUpdateGameTosCommand = {
      gameId: this.activeGame.Id,
      GameTermsOfService: tos,
      adminId: this.activeUser.Id
    }

    this.gameTOSService.updateTos(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully updated the game's terms of service!");
        let gameId = this.activeGame.Id;
        this.router.navigateByUrl(`${gameId}/manage-game`);
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    });
  }

  populateForm() {
    this.gameTOSService.getTos(this.activeGame.Id).subscribe({
      next: tos => {
        this.uiState = UIStateEnum.ShowData
        this.gameTosControl.setValue(tos.GameTos);
        this.causableTosControl.setValue(tos.CausableTos);
        this.tosId = tos.TermsOfServiceId;
      }, error:(err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  formatMarkdown(textP: string) {
    if (textP == undefined) {
      return "";
    }
    return textP.replace(/\n/, "<br>");
  }
}
