<div class="one-column-page-container">
  <h2>Charity Payouts</h2>
  <h3 data-testid="active-game-name">{{ activeGame.Name }} - Charity Balances</h3>
  @if (uiState === uiStateForTemplate.ShowData) {
    <div class="text-end">
      <button mat-button class="primary-button" (click)="onMakePaymentClick()" matTooltip="Make Charity Payment"
              matTooltipShowDelay="500" data-testid="make-payment-button">
        <mat-icon>payment</mat-icon>
        Pay Charities
      </button>
    </div>
    <ag-grid-angular
      class="ag-theme-alpine margin-top-med margin-bottom-med"
      [tooltipShowDelay]=0
      domLayout='autoHeight'
      overlayNoRowsTemplate="<span>No charities for this game</span>"
      (gridReady)="onCharityBreakdownGridReady($event)"
      [rowData]="charityBreakdown"
      [columnDefs]="charityBreakdownDefs">
    </ag-grid-angular>

  } @else {
    <app-loading-card-content></app-loading-card-content>
  }
</div>
