import {Component, inject, Input} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {GameAssetSettingsFormComponent} from "../game-asset-settings-form/game-asset-settings-form.component";
import {GameDrawSettingsComponentForm} from "../game-draw-settings-form/game-draw-settings-component-form.component";
import {GameMetadataSettingsFormComponent} from "../game-metadata-settings-form/game-metadata-settings-form.component";
import {GamePaymentSettingsFormComponent} from "../game-payment-settings-form/game-payment-settings-form.component";
import {GeneralGameSettingsForm} from "../general-game-settings-form/general-game-settings-form.component";
import {MatButton, MatFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {FormGroup} from "@angular/forms";
import {IGameGeneralSettingsForm} from "../../../interfaces/new-game/forms/IGameGeneralSettingsForm";
import {IGameDrawSettingsForm} from "../../../interfaces/new-game/forms/IGameDrawSettingsForm";
import {IGamePaymentSettingsForm} from "../../../interfaces/new-game/forms/IGamePaymentSettingsForm";
import {IGameMetadataSettingsForm} from "../../../interfaces/new-game/forms/IGameMetadataSettingsForm";
import {IGameAssetSettingsForm} from "../../../interfaces/new-game/forms/IGameAssetSettingsForm";
import {NewGameService} from "../../../services/new-game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {DateService} from "../../../services/date.service";
import {IGameMetadataSettings} from "../../../interfaces/new-game/breakdown/IGameMetadataSettings";
import {IGamePaymentSettings} from "../../../interfaces/new-game/breakdown/IGamePaymentSettings";
import {IGameAssetSettings} from "../../../interfaces/new-game/breakdown/IGameAssetSettings";
import {IGameDrawSettings} from "../../../interfaces/new-game/breakdown/IGameDrawSettings";
import {IGameGeneralSettings} from "../../../interfaces/new-game/breakdown/IGameGeneralSettings";
import {concatMap, Observable, of, tap} from "rxjs";
import {ICompleteNewGameRequest} from "../../../interfaces/new-game/ICompleteNewGameRequest";
import {GameService} from "../../../services/game.service";
import {IApiResponseBase} from "../../../interfaces/IApiResponseBase";
import {CharityService} from "../../../services/charity.service";
import {ICharitiesByCategoryResult} from "../../../interfaces/charity/ICharitiesByCategoryResult";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";
import {EditPaymentTiersFiftyFiftyComponent} from "../../edit-payment-tiers/edit-payment-tiers-fifty-fifty.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {
  EditPaymentTiersGoldrushComponent
} from "../../edit-payment-tiers-goldrush/edit-payment-tiers-goldrush.component";
import {MatError} from "@angular/material/form-field";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {AddTosComponent} from "../add-tos/add-tos.component";

@Component({
  selector: 'app-new-game-container',
  standalone: true,
  imports: [
    AsyncPipe,
    GameAssetSettingsFormComponent,
    GameDrawSettingsComponentForm,
    GameMetadataSettingsFormComponent,
    GamePaymentSettingsFormComponent,
    GeneralGameSettingsForm,
    MatButton,
    MatFabButton,
    MatIcon,
    MatStep,
    MatStepLabel,
    MatStepper,
    EditPaymentTiersFiftyFiftyComponent,
    EditPaymentTiersGoldrushComponent,
    MatError,
    MatProgressSpinner,
    AddTosComponent
  ],
  templateUrl: './new-game-container.component.html',
  styleUrl: './new-game-container.component.css'
})
export class NewGameContainerComponent {

  @Input() set newGameIdSetter(newGameIdP: string) {
    const draftGame = this.gameService.getAllDraftGames().find((draftGame) => draftGame.Id === newGameIdP);
    if (draftGame) {
      this.inProgressDraftGame = draftGame;
      this.newGameId = newGameIdP;
      this.newGameService.mapToCompleteNewGameRequest(draftGame);
      this.fetchCharities(newGameIdP);
    } else {
      this.showWrongGameMessage = true;
    }
  }

  private newGameService: NewGameService = inject(NewGameService);
  private gameService: GameService = inject(GameService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private dateService: DateService = inject(DateService);
  private charityService: CharityService = inject(CharityService);

  public gameGeneralForm!: FormGroup<IGameGeneralSettingsForm>;
  public gameDrawSettingsForm!: FormGroup<IGameDrawSettingsForm>;
  public gamePaymentSettingsForm!: FormGroup<IGamePaymentSettingsForm>;
  public gameMetadataSettingsForm!: FormGroup<IGameMetadataSettingsForm>;
  public gameAssetsSettingsForm!: FormGroup<IGameAssetSettingsForm>;
  public newGame$: Observable<ICompleteNewGameRequest> = this.newGameService.selectNewGameRequest();
  public charitiesForNewGame$: Observable<ICharitiesByCategoryResult[]> = this.charityService.charityCategories$;
  public wrongGameIdMessage: string = 'Looks like the game you are trying to reach is not a draft game';
  public showWrongGameMessage: boolean = false;
  public validationErrors: string[] = [];
  protected readonly GameTypeEnum = GameTypeEnum;
  public uiState: UIStateEnum = UIStateEnum.ShowData;

  private newGameId: string = '';
  public inProgressDraftGame: IGameQueryResult | null = null;

  private fetchCharities(newGameIdP: string) {
    this.charityService.fetchCharitiesPerGameByCategory(newGameIdP).subscribe({
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  addNewCharities() {
    this.charityService.openAddCharityDialog(this.newGameId);
  }

  saveGame() {
    this.getSaveGameRequest().subscribe({
        next: () => {
          this.snackbarService.openSuccessfulSnackBar('New Game Saved.')
        }, error: (err) => {
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      }
    )
  }

  getSaveGameRequest(): Observable<any> {
    this.newGameService.saveNewGameGeneral(this.formToGeneralGameSettingsMapper());
    this.newGameService.saveNewGameDraw(this.formToDrawGameSettingsMapper());
    this.newGameService.saveNewGamePayment(this.formToPaymentGameSettingsMapper());
    this.newGameService.saveNewGameAssets(this.formToAssetGameSettingsMapper());
    this.newGameService.saveNewGameMeta(this.formToMetadataGameSettingsMapper());

    const newGameSaveRequest = this.newGameService.mapGameToRequest();

    if (newGameSaveRequest) {
      return this.newGameService.saveGameToApi(newGameSaveRequest);
    }

    return of(null);
  }

  private formToMetadataGameSettingsMapper(): IGameMetadataSettings {
    const controls = this.gameMetadataSettingsForm.value;
    return {
      metadataDescription: controls.metadataDescription ?? '',
      metadataImageUrl: controls.metadataImageUrl ?? '',
      metadataSiteName: controls.metadataSiteName ?? '',
      metadataTitle: controls.metadataTitle ?? '',
      metadataUrl: controls.metadataUrl ?? '',
      metadataType: controls.metadataType ?? '',
      metadataFaviconUrl: controls.metadataFaviconUrl ?? ''
    };
  }

  private formToPaymentGameSettingsMapper(): IGamePaymentSettings {
    const controls = this.gamePaymentSettingsForm.value;
    return {
      causableFeeMultiplier: controls.causableFeeMultiplier ? controls.causableFeeMultiplier / 100 : 0,
      taxMultiplier: controls.taxMultiplier ? controls.taxMultiplier / 100 : 0,
      deductTaxesFromCharityContribution: controls.deductTaxesFromCharityContribution ?? false,
      stripePublicKey: controls.stripePublicKey ?? '',
      administrationCommissionMultiplier: controls.administrationCommissionMultiplier ? controls.administrationCommissionMultiplier / 100 : 0
    };
  }

  private formToAssetGameSettingsMapper(): IGameAssetSettings {
    const controls = this.gameAssetsSettingsForm.value;
    return {
      faqUrl: controls.faqUrl ?? '',
      gameThemeTypeId: controls.gameThemeTypeId ?? 0,
      primaryGameColor: controls.primaryGameColor ?? ''
    };
  }

  private formToDrawGameSettingsMapper(): IGameDrawSettings {
    const controls = this.gameDrawSettingsForm.value;
    return {
      drawDayOfWeek: controls.drawDayOfWeek ?? 0,
      drawTimeMinutesFromMidnightLocal: controls.drawTimeMinutesFromMidnightLocal ? this.dateService.convertMilitaryTimeToMinutes(controls.drawTimeMinutesFromMidnightLocal) : 0,
      manualDraw: controls.manualDraw ?? false,
      maxTicketsPerDraw: controls.maxTicketsPerDraw ?? 0,
      notificationsStartMinutesFromMidnightLocal: controls.notificationsStartMinutesFromMidnightLocal ? this.dateService.convertMilitaryTimeToMinutes(controls.notificationsStartMinutesFromMidnightLocal) : 0,
      instanceIntervalType: controls.instanceIntervalType ?? 0,
      minimumGameLengthInHours: controls.minimumGameLengthInHours ?? 0,
      maxAmountPlayersCanSpendPerDraw: controls.maxAmountPlayersCanSpendPerDraw ? controls.maxAmountPlayersCanSpendPerDraw * 100 : 0,
    };
  }

  private formToGeneralGameSettingsMapper(): IGameGeneralSettings {

    const controls = this.gameGeneralForm.value;
    return {
      licenseNumber: controls.licenseNumber ?? '',
      type: controls.type ?? 0,
      dateOfFirstInstance: controls.dateOfFirstInstance ?? new Date(),
      dateOfFinalInstance: controls.dateOfFinalInstance ?? null,
      loginText: controls.loginText ?? '',
      autoplayEnabled: controls.autoplayEnabled ?? false,
      regionLockPlayerGeolocation: controls.regionLockPlayerGeolocation ?? false,
      regionLockPlayerPostalCode: controls.regionLockPlayerPostalCode ?? false,
      timezone: controls.timezone ?? '',
      collectPlayerAddress: controls.collectPlayerAddress ?? false,
      senderEmail: controls.senderEmail ?? '',
      pushNotificationMessageFeeCost: controls.pushNotificationMessageFeeCost ?? 0,
      maxReceiptAttempts: controls.maxReceiptAttempts ?? 0,
      displayNonWinnerName: controls.displayNonWinnerName ?? false,
      allowFreeTickets: controls.allowFreeTickets ?? false,
      contactEmail: controls.contactEmail ?? '',
      twilioAuthToken: controls.twilioAuthToken ?? '',
      twilioPhoneNumber: controls.twilioPhoneNumber ?? '',
      randomApiOverrideId: controls.randomApiOverrideId ?? '',
      twilioSid: controls.twilioSid ?? '',
      loginMoreInfoUrl: controls.loginMoreInfoUrl ?? '',
      maxPledgesPerPlayer: controls.maxPledgesPerPlayer ?? 0,
      shortName: controls.shortName ?? ''
    };
  }

  onValidateGameClick() {
    this.validationErrors = [];

    this.uiState = UIStateEnum.ShowRequestProcessing;
    this.getSaveGameRequest().pipe(
      tap((saveRes) => {
        if (saveRes != null) {
          this.snackbarService.openSuccessfulSnackBar('New Game Saved.')
        }
      }),
      concatMap((saveRes) => {
        if (saveRes != null) {
          return this.newGameService.completeGameAndValidate();
        }
        return of(null);
      })).subscribe({
      next: (validateResP) => {
        if(validateResP != null) {
          this.uiState = UIStateEnum.ShowData;
          this.snackbarService.openSuccessfulSnackBar('New game is valid.')
        }else {
          this.uiState = UIStateEnum.ShowData;
          this.snackbarService.openErrorSnackBar('Something went wrong validating your game.')
        }

      }, error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        let errors = err.Error as IApiResponseBase;
        this.validationErrors = errors.Errors ?? [];
      }
    })
  }

  protected readonly uiStateEnumForTemplate = UIStateEnum;
}
